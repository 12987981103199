import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { BiDownload } from "react-icons/bi";
import TicketAPIs from "../../APIs/ticket";
import { exportFileToExcel } from "Utils/utils";
import { formatDate } from "date-fns";

const OfficeClearChart = () => {
  const [ticketsCount, setTicketsCount] = useState([0, 0, 0, 0]);

  const getOfficeClearStatsReport = async () => {
    // Dummy API call structure, replace with your actual API call
    try {
      const response = await TicketAPIs.officeClearStatsData();

      if (response.data?.data?.length > 0) {
        // const filtered = response?.data?.data?.filter((el) => {
        //   return el.logType === "PER_TICKET" || el.logType === "AHE_TICKET";
        // });

        // console.log("filtered data", filtered)
        const HEADINGS = [
          {
            v: "Entered Date",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Ticket Number",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Ticket Type",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Ticket Priority",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Station Code",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Type of Work",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Is Office Cleard",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Send To Locate",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
          {
            v: "Need To Be Office Clear",
            t: "s",
            s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
          },
        ];

        const data = response.data?.data?.map((item) => [
          { v: new Date(item.createdAt).toLocaleDateString() },
          { v: item.ticketNo },
          { v: item.ticketType },
          { v: item.priority },
          { v: item.stationCodes?.map((it) => it?.name).join(" ") },
          { v: item.typeOfWork },
          { v: item.isOfficeCleared ? "Yes" : "No" },
          { v: item.sendToLocate ? "Yes" : "No" },
          { v: item.needToBeOfficeClear ? "Yes" : "No" },
        ]);

        const d = [HEADINGS, ...data];
        console.log("d", d);

        exportFileToExcel([HEADINGS, ...data], `Office Clear Stats`);
      }
    } catch (error) {
      console.error("Failed to fetch hour logs ticket report:", error);
      // alert("An error occurred while fetching hour logs ticket report.");
    }
  };

  // Fetch data in a separate function inside useEffect
  const fetchOfficeClearStats = async () => {
    try {
      const response = await TicketAPIs.officeClearStats();
      if (response.data.success) {
        setTicketsCount([
          response.data.data.totalTicketsInOfficeClear,
          response.data.data.totalTicketsOfficeClearedToday,
          response.data.data.remainingTicketsToBeOfficeCleared,
          response.data.data.sendToLocate,
        ]);
      }
    } catch (error) {
      console.error("Failed to fetch office clear stats:", error);
    }
  };

  useEffect(() => {
    fetchOfficeClearStats(); // Fetch the data when the component mounts
  }, []);

  const data = {
    labels: [
      "Total Tickets",
      "Office Cleared",
      "To be Office Cleared",
      "Send To Locate",
    ], // Labels for the chart
    datasets: [
      {
        data: ticketsCount, // Your counts array
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"], // Colors for bars
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide the legend completely
      },
    },
    maintainAspectRatio: false, // Allows you to customize height and width
  };

  return (
    <>
      <h4 className="mb-4">
        Office Clear Stats{" "}
        <BiDownload
          color={"#0073ba"}
          size={24}
          onClick={() => {
            getOfficeClearStatsReport();
          }}
          cursor={"pointer"}
        />
      </h4>
      <h6>{new Date().toLocaleDateString()}</h6>
      <div
        className="p-4 rounded shadow-sm"
        style={{ height: "400px", backgroundColor: "#fff" }}
      >
        <Row className="h-100">
          <Col lg={12}>
            <Bar data={data} options={options} height={300} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OfficeClearChart;
