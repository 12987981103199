import React, { useEffect, useMemo, useState } from "react";
import Heading from "Components/Heading"; // Assuming Heading is a React component
import Footer from "Components/Footer"; // Assuming Footer is a React component
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import classes from "./index.module.scss";
import img1 from "../../Images/dash1.svg";
import SearchIcon from "@mui/icons-material/Search";
import img2 from "../../Images/dash2.svg";
import img3 from "../../Images/dash3.svg";
import img4 from "../../Images/dash4.svg";
import { Bar, Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js"; // Import CategoryScale
import { Chart, registerables } from "chart.js";
import SampleDataTable from "Components/DataTables";
import TicketAPIs from "../../APIs/ticket";
import Loader from "Components/Loader";
import { BiDownload, BiExport, BiLike } from "react-icons/bi";
import { formatDate } from "Helper/Converters";
import { useSelector } from "react-redux";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import * as XLSX from "xlsx-js-style";
import moment from "moment";
import { exportFileToExcel } from "Utils/utils";
import OfficeClearChart from "./officeClearStats";
const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "44px",
  }),
};

const columns = [
  {
    accessor: "ticketNo",
    Header: "Ticket No",
    Cell: ({ row }) => (
      <a
        onClick={(e) => {
          e.preventDefault();
          const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
          // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
          const windowName = `popUpWindow_${row.original.ticketNo}`;
          const windowSize = "width=800,height=600";
          window.open(url, windowName, windowSize);
        }}
        href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
        rel="noopener noreferrer"
        className={`badge ${
          row.original.ticketType == "EMERGENCY" ||
          row.original.priority == "EMERGENCY"
            ? "emergency"
            : row.original.ticketType == "MULTIPLE" ||
              row.original.priority == "MULTIPLE"
            ? "multiple"
            : row.original.ticketType == "PRIORITY" ||
              row.original.priority == "PRIORITY"
            ? "priority"
            : row.original.ticketType == "PROJECT WORK" ||
              row.original.priority == "PROJECT WORK"
            ? "projectWork"
            : row.original.ticketType == "STANDARD" ||
              row.original.priority == "STANDARD"
            ? "standard"
            : "default"
        }`}
      >
        {row.original.ticketNo}
      </a>
    ),
  },
  { accessor: "address", Header: "Location" },
  {
    Header: "Entered Date",
    Cell: ({ row }) => (
      <p>{row.original.createdAt ? formatDate(row.original.createdAt) : ""}</p>
    ),
  },
  {
    Header: "Assigned Date",
    Cell: ({ row }) => (
      <p>
        {row.original.assignDate
          ? moment(row.original.assignDate).format("ll")
          : ""}
      </p>
    ),
  },
  {
    Header: "Reassigned Date",
    Cell: ({ row }) => (
      <p>
        {row.original.reAssignDate
          ? moment(row.original.reAssignDate).format("ll")
          : ""}
      </p>
    ),
  },
  {
    Header: "Station Code",
    Cell: ({ row }) => <p>{row.original.memberStationCode}</p>,
  },
];

const stationCodeCompletionColumns = [
  {
    Header: "Station Code",
    Cell: ({ row }) => <p>{row.original.stationCode}</p>,
  },
  {
    Header: "Completed",
    Cell: ({ row }) => <p>{row.original.completedTickets}</p>,
  },
  {
    Header: "Incomplete",
    Cell: ({ row }) => <p>{row.original.incompleteTickets}</p>,
  },
];

const userStatsColumns = [
  {
    Header: "Name",
    Cell: ({ row }) => (
      <p>{row.original.firstname ? row.original.firstname : "None"}</p>
    ),
  },
  {
    Header: "Completed",
    Cell: ({ row }) => <p>{row.original.completedtickets}</p>,
  },
  {
    Header: "Incomplete",
    Cell: ({ row }) => <p>{row.original.incompletetickets}</p>,
  },
  {
    Header: "Late",
    Cell: ({ row }) => <p>{row.original.rescheduledaftercompletion}</p>,
  },
  {
    Header: "Emergency",
    Cell: ({ row }) => <p>{row.original.emergencytickets}</p>,
  },
];

const options = {
  // ... other options
  indexAxis: "y",
  plugins: {
    legend: {
      display: true, // Show the legend
      position: "bottom", // Position of the legend (for bar chart)
      labels: {
        usePointStyle: true, // Use dataset's point style for legend
      },
      onClick: (e) => {
        // Handle legend item click events here, if needed
      },
      horizontal: true,
    },
  },
};

const options1 = {
  // ... other options
  indexAxis: "x",
  plugins: {
    legend: {
      display: true, // Show the legend
      position: "bottom", // Position of the legend (for bar chart)
      labels: {
        usePointStyle: true, // Use dataset's point style for legend
      },
      onClick: (e) => {
        // Handle legend item click events here, if needed
      },
    },
  },
};

const data2 = {
  // Data for Line Chart with two lines
  labels: [
    "Jan",
    "Fab",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Completed Tickets", // Line 1
      data: [30, 20, 40, 25, 35, 30, 45, 32, 40, 28, 30],
      type: "line",
      borderColor: "#007EF2", // Blue
      borderWidth: 2,
      fill: false,
      tension: 0.5, // Adjust the tension to make the line wavy
      yAxisID: "y1", // Assign a unique yAxisID to each line dataset
    },
    {
      label: "Total Users", // Line 2 (Add a new dataset for the second line)
      data: [5, 15, 25, 0, 30, 12, 0, 0, 20, 35, 18],
      type: "line",
      borderColor: "#C73311", // Red
      borderWidth: 2,
      fill: false,
      tension: 0.5, // Adjust the tension to make the line wavy
      yAxisID: "y2", // Assign a unique yAxisID to each line dataset
    },
  ],
};

const options2 = {
  scales: {
    yAxes: [
      {
        id: "y1",
        position: "left",
        ticks: {
          display: false, // Hide y-axis numbers
        },
        gridLines: {
          display: false, // Hide background grid lines
        },
      },
      {
        id: "y2",
        position: "right",
        ticks: {
          display: false, // Hide y-axis numbers
        },
        gridLines: {
          display: false, // Hide background grid lines
        },
      },
    ],
  },
  plugins: {
    legend: {
      display: true,
      position: "top",
      labels: {
        usePointStyle: true,
      },
      onClick: (e) => {
        // Handle legend item click events here, if needed
      },
    },
  },
};

Chart.register(...registerables);

const DROPDOWN_DATA = [
  { label: "TODAY", value: "TODAY" },
  { label: "WEEK", value: "WEEK" },
  { label: "MONTH", value: "MONTH" },
  { label: "YEAR", value: "YEAR" },
];

const AdminDashboard = () => {
  const { department } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState(DROPDOWN_DATA[0]);

  const getData = async () => {
    setLoading(true);
    const res = await TicketAPIs.adminDashboardStats(startDate, endDate);
    if (res?.data?.success) {
      const userStats =
        res.data.data?.userStats?.filter((item) => item.firstname) || [];
      setData({ ...res.data.data, userStats });
    }
    setLoading(false);
  };
  const formatDateForExcelCompliantTickets = () => {
    let formattedData = [
      [
        {
          v: "Ticket No.",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Ticket Type",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Address",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        // {
        //   v: "Company",
        //   t: "s",
        //   s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        // },
        {
          v: "Contractor Name",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Contractor Phone",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Assigned To",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Entered Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Renegotiated Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Compliance Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
      ],
    ];
    data?.compliantTickets.forEach((item) => {
      formattedData.push([
        { v: item.ticketNo },
        { v: item.priority },
        { v: `${item.digAddress} ${item.region} ${item.digCity}` },
        { v: `${item.company_name} ${item.contractorName}` },
        { v: item.phone },
        {
          v: item.assignTo
            ? `${item.assignTo.firstName} ${item.assignTo.lastName}`
            : "",
        },
        { v: item.createdAt ? formatDate(item.createdAt) : "" },
        { v: item.rescheduleDate ? formatDate(item.rescheduleDate) : "" },
        {
          v: item.originalComplianceDate
            ? formatDate(item.originalComplianceDate)
            : "",
        },
      ]);
    });
    return formattedData;
  };
  const formatDateForExcelOCTickets = () => {
    let formattedData = [
      [
        {
          v: "Ticket No.",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Ticket Type",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Address",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Company",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Contractor Name",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Contractor Phone",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Assigned To",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Entered Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Renegotiated Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
        {
          v: "Compliance Date",
          t: "s",
          s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } },
        },
      ],
    ];
    data?.compliantTickets.forEach((item) => {
      formattedData.push([
        { v: item.ticketNo },
        { v: item.ticketType },
        { v: `${item.digAddress} ${item.region}` },
        { v: item.company_name },
        { v: item.contractorName },
        { v: item.phone },
        {
          v: item.assignTo
            ? `${item.assignTo.firstName} ${item.assignTo.lastName}`
            : "",
        },
        { v: item.createdAt ? formatDate(item.createdAt) : "" },
        { v: item.rescheduleDate ? formatDate(item.rescheduleDate) : "" },
        {
          v: item.originalComplianceDate
            ? formatDate(item.originalComplianceDate)
            : "",
        },
      ]);
    });
    return formattedData;
  };

  const exportToExcel = (data) => {
    const fileName = `Compliant Report ${
      new Date(startDate).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }) +
      "-" +
      new Date(endDate).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    }`;

    exportFileToExcel(data, fileName);
  };
  useEffect(() => {
    getData();
  }, []);

  const stationCodeStatsData = useMemo(() => {
    const stationCodes =
      data?.stationCodes?.map((item) => item.stationCode) || [];
    const completedTickets =
      data?.stationCodes?.map((item) => Number(item.completedTickets)) || [];
    const incompleteTickets =
      data?.stationCodes?.map((item) => Number(item.incompleteTickets)) || [];
    return {
      // Separate datasets for bars and line
      labels: stationCodes,
      datasets: [
        {
          // Bar Dataset 1
          label: "Completed Tickets",
          data: completedTickets,
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 0,
          metadata: {
            customLabel: "Assigned Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Incomplete Tickets",
          data: incompleteTickets,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 0,
          metadata: {
            customLabel: "Reassigned Tickets Count", // Optional custom label for legend
          },
        },
        // {
        //   // Bar Dataset 2
        //   label: "Total Users",
        //   data: [5, 15, 25, 0, 30, 12, 0, 0, 20, 35, 18],
        //   backgroundColor: "#0073BA",
        //   borderColor: "#0073BA",
        //   borderWidth: 0,
        //   metadata: {
        //     customLabel: "Total Users", // Optional custom label for legend
        //   },
        // },
        // {
        //   // Line Dataset (Not displayed in bar chart)
        //   label: "Line",
        //   data: [10, 15, 30, 20, 30, 25, 25, 22, 35, 18, 18],
        //   type: "line",
        //   fill: false,
        //   borderColor: "#B9B9B9",
        //   borderWidth: 2,
        //   legend: {
        //     display: false,
        //   },
        // },
      ],
    };
  }, [data]);

  const userStatsData = useMemo(() => {
    const userLabel = data?.userStats?.map((item) => item.firstname || "None");

    const completedTickets =
      data?.userStats?.map((item) => Number(item.completedtickets)) || [];
    const incompleteTickets =
      data?.userStats?.map((item) => Number(item.incompletetickets)) || [];
    const lateTickets =
      data?.userStats?.map((item) => Number(item.rescheduledaftercompletion)) ||
      [];
    const emergencyTickets =
      data?.userStats?.map((item) => Number(item.emergencytickets)) || [];
    return {
      // Separate datasets for bars and line
      labels: userLabel,
      datasets: [
        {
          // Bar Dataset 1
          label: "Completed Tickets",
          data: completedTickets,
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 0,
          metadata: {
            customLabel: "Assigned Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Incomplete Tickets",
          data: incompleteTickets,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 0,
          metadata: {
            customLabel: "Incomplete Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Late Tickets",
          data: lateTickets,
          backgroundColor: "red",
          borderColor: "red",
          borderWidth: 0,
          metadata: {
            customLabel: "Late Tickets Count", // Optional custom label for legend
          },
        },
        {
          // Bar Dataset 2
          label: "Emergency Tickets",
          data: emergencyTickets,
          backgroundColor: "yellow",
          borderColor: "yellow",
          borderWidth: 0,
          metadata: {
            customLabel: "Emergency Tickets Count", // Optional custom label for legend
          },
        },
      ],
    };
  }, [data]);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <section>
          <div className="d-flex justify-content-between">
            <Heading text={"Admin Dashboard"} />
            <Form.Group className="d-flex form-group date-range-picker me-2">
              <div className="me-3">
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="form-control"
                />
              </div>

              <div className="me-2">
                <ReactDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End Date"
                  className="form-control"
                />
              </div>
              <div
                className="search-icon-date-selection"
                onClick={() => getData()}
              >
                <SearchIcon />
              </div>
            </Form.Group>
          </div>
          <Row className=" align-items-stretch">
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img2} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.totalTickets}</h3>
                  <h6 className="text-muted">Total Tickets</h6>
                </div>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img2} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.completedTickets}</h3>
                  <h6 className="text-muted">Completed Tickets</h6>
                </div>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img3} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.assignedOrReassignedTickets}</h3>
                  <h6 className="text-muted">Assigned Tickets</h6>
                </div>
              </Card>
            </Col>
            <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img3} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.compliantTickets.length}</h3>
                  <h6 className="text-muted">Compliant Tickets</h6>
                </div>
                <span
                  role="button"
                  className="ms-3 ms-md-4 cursor-pointer"
                  onClick={() =>
                    exportToExcel(formatDateForExcelCompliantTickets())
                  }
                >
                  <BiDownload color={"#0073ba"} size={24} />
                </span>
              </Card>
            </Col>
            {/* <Col xl={3} sm={6} className="mb-3">
              <Card className={classes.card}>
                <div className={classes.icon}>
                  <img src={img3} alt="img" />
                </div>
                <div>
                  <h3 className="mb-3">{data?.compliantTickets.length}</h3>
                  <h6 className="text-muted">Office Clear Tickets</h6>
                </div>
                  <span role="button" className="ms-3 ms-md-4 cursor-pointer" onClick={()=>exportToExcel(formatDateForExcelOCTickets())}>
                        <BiDownload color={'#0073ba'} size={24}/>
                      </span>
              </Card>
            </Col> */}
          </Row>
          {(department?.name == "manager" || department?.name == "locator") && (
            <Row className="mb-3 align-items-stretch">
              <Col lg={6}>
                <Card className="h-100">
                  <h6>Station Codes</h6>
                  <SampleDataTable
                    columns={stationCodeCompletionColumns}
                    data={data?.stationCodes || []}
                  />
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="h-100">
                  <h6>Statistics</h6>
                  <Bar data={stationCodeStatsData} options={options} />
                </Card>
              </Col>
            </Row>
          )}
          {(department?.name == "admin" || department?.name == "Tester") && (
            <>
              <Row className="mb-3 align-items-stretch">
                <Col lg={6}>
                  <Card className="h-100">
                    <h6>User Tickets Stats</h6>
                    <SampleDataTable
                      columns={userStatsColumns}
                      data={data?.userStats || []}
                      resultsTitle="Total Users Found"
                      noDataMsg="No Users Found"
                    />
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="h-100">
                    <h6>User Statistics</h6>
                    <Bar data={userStatsData} options={options2} />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card className="h-100 p-3">
                    <OfficeClearChart />
                  </Card>
                </Col>
                <Col lg={6}></Col>
              </Row>
            </>
          )}
          {(department?.name == "manager" || department?.name == "locator") && (
            <Card>
              <Card>
                <h6>Latest Tickets</h6>
                <SampleDataTable
                  columns={columns}
                  data={data?.latestTickets || []}
                />
              </Card>
              <Footer />
            </Card>
          )}
        </section>
      )}
    </>
  );
};

export default AdminDashboard;
